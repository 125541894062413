import { Modal, Tabs, Form, Input } from "antd";
import "antd/dist/antd.css";

const inputDisabled = (label, name, value) => (
  <Form.Item initialValue={value} label={label} name={name}>
    <Input
      disabled="false"
      style={{
        width: 450,
        margin: "0 10px",
        color: "#000000",
      }}
    />
  </Form.Item>
);

export default inputDisabled;
