import { Modal, Divider } from "antd";
import "antd/dist/antd.css";

const infoModal = (record, flag) => {
  Modal.info({
    width: "600px",
    style: { top: 30 },
    title: flag == "request" ? "Requests Details" : "User Details",
    content:
      flag == "request" ? (
        <div>
          <Divider orientation="left">Title</Divider>
          <p style={{ marginLeft: "60px" }}>{record.delivery_id.title}</p>

          <Divider orientation="left">Description</Divider>
          <p style={{ marginLeft: "60px" }}>{record.delivery_id.description}</p>

          <Divider orientation="left">Address from :</Divider>
          <p style={{ marginLeft: "60px" }}>
            {record.delivery_id.deliverd_from.address}
          </p>

          <Divider orientation="left">Address to :</Divider>
          <p style={{ marginLeft: "60px" }}>
            {record.delivery_id.deliverd_to.address}
          </p>

          <Divider orientation="left">Name </Divider>
          <p style={{ marginLeft: "60px" }}>
            {record.delivery_id.user_id.name}
          </p>

          <Divider orientation="left">Lastname </Divider>
          <p style={{ marginLeft: "60px" }}>
            {record.delivery_id.user_id.lastname}
          </p>

          <Divider orientation="left">Email </Divider>
          <p style={{ marginLeft: "60px" }}>
            {record.delivery_id.user_id.email}
          </p>

          <Divider orientation="left">Phone </Divider>
          <p style={{ marginLeft: "60px" }}>
            {"+" + record.delivery_id.user_id.phone}
          </p>
        </div>
      ) : flag == "claim" ? (
        <div>
          <Divider orientation="left">Name</Divider>
          <p style={{ marginLeft: "60px" }}>{record.user_id.name}</p>
          <Divider orientation="left">Lastname</Divider>
          <p style={{ marginLeft: "60px" }}>{record.user_id.lastname}</p>
          <Divider orientation="left">Email</Divider>
          <p style={{ marginLeft: "60px" }}>{record.user_id.email}</p>
          <Divider orientation="left">Phone</Divider>
          <p style={{ marginLeft: "60px" }}>{"+" + record.user_id.phone}</p>
        </div>
      ) : null,
    onOk() {},
  });
};

export default infoModal;
