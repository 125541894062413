import { Modal, Tabs, Form, Input } from "antd";
import "antd/dist/antd.css";
import inputDisabled from "./inputDisabled";
import { DropboxOutlined, UserOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

const infoModalTab = (record) => {
  console.log(record);
  Modal.info({
    style: { top: 20 },
    width: "600px",
    title: "Details",

    content: (
      <Tabs
        defaultActiveKey="1"
        //centered
      >
        <TabPane
          tab={
            <span>
              <UserOutlined />
              User Details
            </span>
          }
          key="1"
        >
          <Form
            style={{ paddingLeft: "100px", paddingTop: "10px" }}
            style={{ paddingTop: "10px" }}
            layout={"vertical"}
            name="basic"
            initialValues={{
              remember: true,
            }}
          >
            {inputDisabled("Email", "email", record.user_id.email)}
            {inputDisabled("Name", "name", record.user_id.name)}
            {inputDisabled("Lastname", "lastname", record.user_id.lastname)}
            {inputDisabled("Username", "username", record.user_id.username)}
            {inputDisabled("Phone", "phone", record.user_id.phone)}
            {inputDisabled(
              "Country",
              "country",
              record.user_id.delivery_country
            )}
            {inputDisabled("State", "state", record.user_id.delivery_state)}
          </Form>
        </TabPane>
        <TabPane
          //style={{ textAlign: "center" }}
          tab={
            <span>
              <DropboxOutlined />
              Delivery Details
            </span>
          }
          key="2"
        >
          <Form
            //style={{ paddingLeft: "100px", paddingTop: "10px" }}
            //style={{ paddingTop: "10px" }}
            layout={"vertical"}
            name="basic"
            initialValues={{
              remember: true,
            }}
          >
            {inputDisabled("Title", "title", record.title)}
            {inputDisabled("Description", "description", record.description)}

            {inputDisabled(
              "Full address from ",
              "Full address",
              record.deliverd_from.address
            )}
            {inputDisabled(
              "Country from",
              "country",
              record.deliverd_from.country
            )}
            {inputDisabled("State from", "state", record.deliverd_from.state)}

            {inputDisabled(
              "Full address to",
              "Full address",
              record.deliverd_to.address
            )}
            {inputDisabled("Country to", "country", record.deliverd_to.country)}
            {inputDisabled("State to", "state", record.deliverd_to.state)}
          </Form>
        </TabPane>
      </Tabs>
    ),

    onOk() {},
  });
};

export default infoModalTab;
