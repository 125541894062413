import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, CLEAR_USER } from "../CONSTANTS";

const initialState = {
  accessToken: JSON.parse(localStorage.getItem("accessToken")),
  isAuthenticated: JSON.parse(localStorage.getItem("accessToken")) != undefined,
  loading: true,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("accessToken", JSON.stringify(payload.accessToken));
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        error: null,
      };
    case LOGIN_FAIL:
      localStorage.clear();
      return {
        ...state,
        accessToken: null,
        isAuthenticated: false,
        loading: false,
        error: payload,
      };
    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        accessToken: null,
        isAuthenticated: false,
        loading: false,
        error: null,
      };
    case CLEAR_USER:
      return {
        accessToken: null,
        isAuthenticated: false,
        loading: false,
        _id: null,
        email: null,
        name: null,
        lastname: null,
        username: null,
        error: null,
      };
    default:
      return state;
  }
}
