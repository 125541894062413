import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import usersList from "./screens/users/usersList";
import requestsList from "./screens/requests/requestsList";
import claimList from "./screens/claim/claimList";
import deliveryList from "./screens/delivery/deliveryList";
import notificationList from "./screens/notification/notificationList";
import login from "./screens/login/login";

//Redux
import { Provider } from "react-redux";
import store from "./redux/store";

//private route :
import PrivateRoute from "./components/privateRoute";

function App() {
  return (
    <div>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/login" component={login}></Route>
            <PrivateRoute exact path="/" component={usersList}></PrivateRoute>
            <PrivateRoute
              exact
              path="/request"
              component={requestsList}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/claim"
              component={claimList}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/delivery"
              component={deliveryList}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/notification"
              component={notificationList}
            ></PrivateRoute>
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
