import React from "react";
import "antd/dist/antd.css";
import { Layout } from "antd";
const { Footer } = Layout;

function Footers(props) {

  return (
    <Footer style={{ textAlign: "center", }}>
      We Delivery Dashboard ©2021
    </Footer>
  );
}

Footer.propTypes = {};

export default Footers;
