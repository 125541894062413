import React, { Fragment, useState } from "react";
import "antd/dist/antd.css";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { Layout, Avatar, Menu, Typography } from "antd";
import Icon, {
  NotificationOutlined,
  DropboxOutlined,
  UnorderedListOutlined,
  FolderAddOutlined,
  ContainerOutlined,
  UserOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

function Sliders(props) {
  //int state :
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    console.log(collapsed);
    setCollapsed(collapsed);
  };

  return (
    <Fragment>
      <Sider
        collapsible="true"
        collapsed={collapsed}
        onCollapse={(collapsed) => onCollapse(collapsed)}
      >
        <div className="logo" style={{ margin: 10 }}>
          <Avatar
            size="large"
            style={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              marginLeft: 5,
            }}
            col
            icon={<UserOutlined />}
          />
          {props.isAuthenticated && props.username != null ? (
            <span style={{ color: "#fff", marginLeft: 5 }}>
              {props.username}
            </span>
          ) : (
            <span style={{ color: "#fff", marginLeft: 5 }}>Admin</span>
          )}
        </div>
        <Menu defaultSelectedKeys={[""]} theme="dark" mode="inline">
          <Menu.Item key="1" icon={<UserOutlined />} color="#fff">
            <Link to={"/"}>Users</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<DropboxOutlined />}>
            <Link to={"/delivery"}>Deliverys</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<UnorderedListOutlined />}>
            <Link to={"/request"}>Requests</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<FolderAddOutlined />}>
            <Link to={"/claim"}>Claims</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<NotificationOutlined />}>
            <Link to={"/notification"}>Notifications</Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  username: state.auth.username,
});

export default connect(mapStateToProps, {})(Sliders);
