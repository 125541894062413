import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Slider from "../../layout/sliders";
import Footer from "../../layout/footers";
import Header from "../../layout/headers";
import { API_URL } from "../../constants";
import infoModal from "../../components/infoModal";
import axios from "axios";

//redux
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";

import "antd/dist/antd.css";
import {
  UserOutlined,
  FolderAddOutlined,
  EditOutlined,
  CheckCircleOutlined,
  RedoOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Breadcrumb,
  Table,
  Col,
  Row,
  Select,
  Tag,
  Avatar,
  Input,
  Form,
  Space,
  Button,
  Switch,
  message,
  Modal,
  Popconfirm,
  Typography,
} from "antd";
const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;
const { confirm } = Modal;

function UsersList(props) {
  //from state :
  const [form] = Form.useForm();
  const [UsersListState, setUsersListState] = useState([]);
  const [isLoading, setLoading] = useState(false);

  //sort & modal
  const [sortedInfo, setSortedInfo] = useState({});
  const [ID, setID] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalMailVisible, setIsModalMailVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);

  //filter
  const [disabled, setDisabled] = useState(false);
  const [RoleSelected, setRoleSelected] = useState("all");
  const [StatusSelectedModal, setStatusSelectedModal] = useState("");
  const [EmailSelected, setEmailSelected] = useState("");
  const [EmailUsersListState, setEmailUsersListState] = useState([]);
  const [UsersSelectStatus, setUsersSelectStatus] = useState("");

  useEffect(() => {
    getListUsers();
  }, [RoleSelected, isLoading, EmailSelected, UsersSelectStatus]);

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  function handleChangeSelect(value) {
    setRoleSelected(value);
  }

  const getListUsers = async () => {
    try {
      const res = await axios.get(API_URL + "/admin/users", {
        params: {
          role: RoleSelected,
          statusBand: UsersSelectStatus,
          email: EmailSelected,
        },
        headers: {
          Authorization: JSON.parse(localStorage.getItem("accessToken")),
        },
      });
      if (res.status === 200) {
        setLoading(false);
        setUsersListState(res.data);
        getListEmailUsers(res.data);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  const getListEmailUsers = (data) => {
    var ListEmail = data.map((user) => user.email);
    var UniqueEmail = ListEmail.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setEmailUsersListState(UniqueEmail);
  };
  const ValidateTr = async (record) => {
    try {
      const res = await axios.put(
        API_URL + "/admin/validateTransporteur/" + record._id,
        {
          isVerified: true,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("accessToken")),
          },
        }
      );
      if (res.status === 201) {
        setLoading(true);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };
  const BanSuspendUser = async (record) => {
    try {
      const res = await axios.put(
        API_URL + "/admin/suspend/" + record._id,
        {
          banned: !record.banned,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("accessToken")),
          },
        }
      );
      if (res.status === 201) {
        setLoading(true);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  const showModal = async (id) => {
    console.log(id);
    setID(id);
    setIsModalVisible(true);
  };

  const showModalMail = async (record) => {
    form.setFieldsValue({
      email: record.email,
    });
    setIsModalMailVisible(true);
  };

  const showModalUser = async (record) => {
    setID(record._id);
    form.setFieldsValue({
      email_update: record.email,
      username_update: record.username,
      lastname_update: record.lastname,
      password_update: record.password,
      name_update: record.name,
    });
    setIsModalUpdateVisible(true);
  };

  const handleOkUpdate = async () => {
    if (
      form.getFieldValue("password_update") != undefined &&
      form.getFieldValue("name_update") != undefined &&
      form.getFieldValue("lastname_update") != undefined &&
      form.getFieldValue("username_update") != undefined &&
      form.getFieldValue("password_update").length >= 8
    ) {
      try {
        const res = await axios.put(
          API_URL + "/admin/user/" + ID,
          {
            name: form.getFieldValue("name_update"),
            lastname: form.getFieldValue("lastname_update"),
            username: form.getFieldValue("username_update"),
            password: form.getFieldValue("password_update"),
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("accessToken")),
            },
          }
        );
        if (res.status === 201) {
          setLoading(true);
          setID("");
          setIsModalUpdateVisible(false);
          message.success("User Info has been updated successfuly ", [3]);
        }
      } catch (err) {
        console.log("There was a problem with the server");
      }
    } else {
      message.error("Entre missing input", [2]);
    }
  };

  const handleCancelUpdate = () => {
    setIsModalUpdateVisible(false);
  };

  const handleCancelMail = () => {
    setIsModalMailVisible(false);
  };

  const handleOkMail = async () => {
    sendMail();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      const res = await axios.put(
        API_URL + "/admin/suspend/" + ID,
        {
          banned: StatusSelectedModal,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("accessToken")),
          },
        }
      );
      if (res.status === 201) {
        setLoading(true);
        setID("");
        setIsModalVisible(false);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      ellipsis: true,
      render: (text) => (
        <>
          {" "}
          <Avatar
            style={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              marginRight: "10px",
            }}
          >
            {text.substring(0, 1).toUpperCase()}
          </Avatar>
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => a.username.length - b.username.length,
      sortOrder: sortedInfo.columnKey === "username" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Lastname",
      dataIndex: "lastname",
      key: "lastname",
      sorter: (a, b) => a.lastname.length - b.lastname.length,
      sortOrder: sortedInfo.columnKey === "lastname" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.length - b.email.length,
      sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {RoleSelected == "Admin" ? setDisabled(true) : setDisabled(false)}
          <Space direction="vertical" style={{ paddingBottom: '2px' }}>
            <Space style={{ paddingBottom: '2px' }}>
              {record.banned == false ? (
                <Button
                  type="danger"
                  style={{ width: "110px" }}
                  onClick={() => showBanConfirm(record)}
                  icon={<UserOutlined />}
                >
                  Suspend
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#87d068",
                    color: "#fff",
                    width: "110px",
                  }}
                  onClick={() => showBanConfirm(record)}
                  //onClick={() => showModal(record._id)}
                  icon={<UserOutlined />}
                >
                  UnBaned
                </Button>
              )}
              <Button
                onClick={() => showModalMail(record)}
                type="primary"
                style={{ width: "110px" }}
                icon={<MailOutlined />}
              >
                Send Mail
              </Button>
            </Space>
            <Space style={{ paddingBottom: '2px' }}>

              <Button
                size="middle"
                color="warning"
                style={{
                  width: "110px",
                  backgroundColor: "#FCB362",
                  color: "#fff",
                }}
                onClick={() => showModalUser(record)}
                icon={<EditOutlined />}
              >
                Edit Info
              </Button>

              {record.delivery_country != '' && record.isVerified != true ? (
                <Button
                  size="middle"
                  style={{
                    width: "110px",
                    backgroundColor: "#2ecc71",
                    color: "#fff",
                  }}
                  onClick={() => ShowvalidateTr(record)}
                  icon={<UserOutlined />}
                >
                  Validate
                </Button>
              ) : (

                <Button
                  ghost
                  disabled
                  size="middle"
                  style={{
                    width: "110px",

                  }}
                  onClick={() => ShowvalidateTr(record)}
                  icon={<UserOutlined />}
                >
                  Validate
                </Button>
              )}

            </Space>
          </Space>

        </>
      ),
    },
  ];

  function handleChangeModal(value) {
    setStatusSelectedModal(value);
  }

  function handleChangeSelectEmail(value) {
    setEmailSelected(value);
  }

  function handleChangeSelectStatus(value) {
    setUsersSelectStatus(value);
  }

  const ShowvalidateTr = async (record) => {
    confirm({
      title: "Alert !",
      icon: <ExclamationCircleOutlined type="success" />,
      content: "Do you want to validate this Transporteur?",
      okText: "Yes",
      okType: "success",
      cancelText: "No",
      onOk() {
        console.log("OK");
        ValidateTr(record);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };


  const showBanConfirm = async (record) => {
    confirm({
      title: "Alert !",
      icon: <ExclamationCircleOutlined />,
      content: !record.banned
        ? "Do you want to Suspend this user"
        : "Do uou want to Unbaned this user",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        BanSuspendUser(record);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const sendMail = async () => {
    try {
      const res = await axios.post(
        API_URL + "/admin/sendMail/",
        {
          email: form.getFieldValue("email"),
          subject: form.getFieldValue("subject"),
          content: form.getFieldValue("content"),
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("accessToken")),
          },
        }
      );
      if (res.status === 201) {
        setIsModalMailVisible(false);
        message.success("Your Email has been send successfuly ", [3]);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  const resetFilter = () => {
    setRoleSelected("all");
    setEmailSelected("all");
    setUsersSelectStatus("all");
    form.setFieldsValue({
      email: "all",
      role: "all",
      status: "all",
    });
  };

  if (!props.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Slider />
      <Layout className="site-layout">
        <Header headerTitle="User List" />
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}></Breadcrumb>
          <div
            className="site-layout-background"
            style={{ padding: 15 }}
          >
            <div>
              <Form form={form}>
                <Row justify="space-between">
                  <Col span={5}>
                    <Form.Item name="role" label="Role">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                        }
                        defaultValue="all"
                        style={{ width: 200 }}
                        onChange={handleChangeSelect}
                      >
                        <Option value="all">All</Option>
                        <Option value="Client">Client</Option>
                        {/*<Option value="Admin">Admin</Option>*/}
                        <Option value="Transporter">Transporter</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col >
                    <Form.Item name="statusBanned" label="Status">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                        }
                        defaultValue="all"
                        style={{ width: 200 }}
                        onChange={handleChangeSelectStatus}
                      >
                        <Option value="">All</Option>
                        <Option value="true">Suspend</Option>
                        <Option value="false">Unbaned</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col >
                    <Form.Item name="email" label="Email">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                        }
                        listHeight={100}
                        defaultValue="all"
                        style={{ width: 200 }}
                        onChange={handleChangeSelectEmail}
                      >
                        <Option value="all">All Email</Option>
                        {EmailUsersListState.map((email) => (
                          <Option value={email}> {email}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item>
                      <Button
                        type="primary"
                        danger
                        icon={<RedoOutlined />}
                        onClick={() => resetFilter()}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Table
                    pagination={{ pageSize: 4 }}
                    dataSource={UsersListState}
                    columns={columns}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Modal
                  title="Update User"
                  width={500}
                  visible={isModalUpdateVisible}
                  onOk={handleOkUpdate}
                  onCancel={handleCancelUpdate}
                >
                  <Form
                    encType="multipart/form-data"
                    form={form}
                    style={{ paddingTop: "10px", textAlign: "center" }}
                    layout={"vertical"}
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                  >
                    <Form.Item
                      label="Email"
                      name="email_update"
                      rules={[
                        {
                          required: true,
                          message: "Please entre email of the user !",
                        },
                      ]}
                    >
                      <Input
                        disabled="false"
                        style={{
                          width: 350,
                          margin: "0 5px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Username"
                      name="username_update"
                      rules={[
                        {
                          required: true,
                          message: "Please entre Username !",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: 350,
                          margin: "0 10px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Name"
                      name="name_update"
                      rules={[
                        {
                          required: true,
                          message: "Please entre name  !",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: 350,
                          margin: "0 10px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Lastname"
                      name="lastname_update"
                      rules={[
                        {
                          required: true,
                          message: "Please entre lastname !",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: 350,
                          margin: "0 10px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      value={form.getFieldValue("password_update")}
                      name="password_update"
                      rules={[
                        {
                          required: true,
                          message: "Please entre password !",
                        },
                      ]}
                    >
                      <Input.Password
                        style={{
                          width: 350,
                          margin: "0 10px",
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Modal>

                <Modal
                  title="Block User"
                  width={350}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Form>
                    <Form.Item>
                      <Space size="middle">
                        <Select
                          defaultValue="Select One"
                          style={{ width: 300 }}
                          onChange={handleChangeModal}
                        >
                          <Option value="false">Suspend</Option>
                          <Option value="true">Unband</Option>
                        </Select>
                      </Space>
                    </Form.Item>
                    <Form.Item></Form.Item>
                  </Form>
                </Modal>
                <Modal
                  title="Send Email To User"
                  width={500}
                  visible={isModalMailVisible}
                  onOk={handleOkMail}
                  onCancel={handleCancelMail}
                >
                  <Form
                    encType="multipart/form-data"
                    form={form}
                    style={{ paddingTop: "10px", textAlign: "center" }}
                    layout={"horizontal"}
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please entre email of the user !",
                        },
                      ]}
                    >
                      <Input
                        disabled="false"
                        style={{
                          width: 350,
                          margin: "0 5px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        {
                          required: true,
                          message: "Please entre subject !",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: 350,
                          margin: "0 10px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Content"
                      name="content"
                      rules={[
                        {
                          required: true,
                          message: "Please entre content of the message  !",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{
                          width: 350,
                          height: 150,
                          margin: "0 10px",
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Modal>

                <Modal
                  title="Block User"
                  width={350}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Form>
                    <Form.Item>
                      <Space size="middle">
                        <Select
                          defaultValue="Select One"
                          style={{ width: 300 }}
                          onChange={handleChangeModal}
                        >
                          <Option value="false">Suspend</Option>
                          <Option value="true">Unband</Option>
                        </Select>
                      </Space>
                    </Form.Item>
                    <Form.Item></Form.Item>
                  </Form>
                </Modal>
              </Form>
            </div>
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout >
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  id_current_user: state.auth.id,
});

export default connect(mapStateToProps, { logout })(UsersList);
