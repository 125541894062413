import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Slider from "../../layout/sliders";
import Footer from "../../layout/footers";
import Header from "../../layout/headers";
import axios from "axios";
import { API_URL } from "../../constants";
import infoModal from "../../components/infoModal";

//redux
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";

import "antd/dist/antd.css";
import {
  UserOutlined,
  RedoOutlined,
  FolderAddOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  ContactsOutlined,
  SyncOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Breadcrumb,
  Table,
  Spin,
  Col,
  Drawer,
  Row,
  Form,
  Divider,
  Select,
  Tag,
  Space,
  Button,
  Avatar,
  Typography,
  Modal,
} from "antd";
const { Content } = Layout;
const { Option } = Select;
const { Text, Link } = Typography;

function RequestsList(props) {
  //form
  const [form] = Form.useForm();

  //table & sort
  const [sortedInfo, setSortedInfo] = useState({});
  const [RequestListState, setRequestListState] = useState([]);
  const [StatusSelected, setStatusSelected] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [DeliverydetailsState, setDeliverydetailsState] = useState({});

  //filter :
  const [UsersListState, setUsersListState] = useState([]);
  const [EmailSelected, setEmailSelected] = useState("");

  //modal state :
  const [isModalMailVisible, setIsModalMailVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getListRequests();
    //getListUsers();
  }, [StatusSelected, isLoading, EmailSelected]);

  const handleChangeTable = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  const getListRequests = async () => {
    try {
      const res = await axios.get(API_URL + "/admin/requests", {
        params: { status: StatusSelected, email: EmailSelected },
        headers: {
          Authorization: JSON.parse(localStorage.getItem("accessToken")),
        },
      });
      if (res.status === 200) {
        setLoading(false);
        setRequestListState(res.data);
        getListUsers(res.data);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  const getListUsers = (data) => {
    var ListEmail = data.map((user) => user.transporter_id.email);
    var UniqueEmail = ListEmail.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setUsersListState(UniqueEmail);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      sorter: (a, b) =>
        a.transporter_id.name.length - b.transporter_id.name.length,
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      ellipsis: true,
      render: (text, record) => (
        <>
          {" "}
          <Avatar
            style={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              marginRight: "10px",
            }}
          >
            {record.transporter_id.name.substring(0, 1).toUpperCase()}
          </Avatar>
          <a>
            {" "}
            {record.transporter_id.name + " " + record.transporter_id.lastname}
          </a>{" "}
        </>
      ),
    },
    {
      title: "Delivery reference number",
      key: "delivery reference number",
      render: (text, record) => (
        <Text>
          {record.delivery_id._id
            .slice(record.delivery_id._id.length - 10)
            .toUpperCase()}
        </Text>
      ),
    },
    {
      title: "Delivery price",
      dataIndex: "delivery_price",
      key: "delivery_price",
      // sorter: (a, b) => a.delivery_price.length < b.delivery_price.length,
      // sortOrder: sortedInfo.columnKey === "delivery_price" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Email Transporter",
      key: "email",
      sorter: (a, b) =>
        a.transporter_id.email.length - b.transporter_id.email.length,
      sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
      ellipsis: true,
      render: (text, record) => <Text>{record.transporter_id.email}</Text>,
    },
    {
      title: "Status",
      key: "status",

      render: (text, record) =>
        record.status == "accpted" ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Accpted
          </Tag>
        ) : record.status == "onhold" ? (
          <Tag icon={<SyncOutlined spin />} color="warning">
            Onhold
          </Tag>
        ) : (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Refused
          </Tag>
        ),
    },
    {
      title: "Created At",
      key: "createdAt",
      render: (text, record) => (
        <Text>{record.createdAt.substring(0, 10)}</Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<InfoCircleOutlined />}
            //onClick={() => showDrawer()}
            onClick={() => infoModal(record, "request")}
          >
            Details
          </Button>
        </Space>
      ),
    },
  ];

  function handleChangeSelect(value) {
    setStatusSelected(value);
  }

  function handleChangeSelectEmail(value) {
    setEmailSelected(value);
  }

  const resetFilter = () => {
    setStatusSelected("all");
    setEmailSelected("all");
    form.setFieldsValue({
      countryfrom: "all",
      countryto: "all",
      email: "all",
      status: "all",
    });
  };

  if (!props.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Slider />
      <Layout className="site-layout">
        <Header headerTitle="Request List" />
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}></Breadcrumb>
          <div
            className="site-layout-background"
            style={{ padding: 15, }}
          >
            <Form form={form}>
              <Row justify="space-between">
                <Col span={5}>
                  <Form.Item name="status" label="Status">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                      defaultValue="all"
                      style={{ width: 200 }}
                      onChange={handleChangeSelect}
                    >
                      <Option value="all">All</Option>
                      <Option value="accpted">Accpted</Option>
                      <Option value="onhold">Onhold</Option>
                      <Option value="refused">Refused</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="email" label="Email">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                      listHeight={100}
                      defaultValue="all"
                      style={{ width: 200 }}
                      onChange={handleChangeSelectEmail}
                    >
                      <Option value="all">All Email</Option>
                      {UsersListState.map((email) => (
                        <Option value={email}> {email}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item>
                    <Button
                      type="primary"
                      danger
                      icon={<RedoOutlined />}
                      onClick={() => resetFilter()}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Table
                  pagination={{ pageSize: 4 }}
                  dataSource={RequestListState}
                  columns={columns}
                  onChange={handleChangeTable}
                />
              </Form.Item>
            </Form>
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  id_current_user: state.auth.id,
});

export default connect(mapStateToProps, { logout })(RequestsList);
