import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Slider from "../../layout/sliders";
import Footer from "../../layout/footers";
import Header from "../../layout/headers";
import { API_URL } from "../../constants";
import axios from "axios";
import "antd/dist/antd.css";

//redux
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";

import {
  UserOutlined,
  FolderAddOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  SendOutlined,
  SyncOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Breadcrumb,
  Table,
  Col,
  Row,
  Select,
  Tag,
  Avatar,
  Input,
  Form,
  Space,
  Button,
  Switch,
  message,
  Modal,
  Popconfirm,
  Typography,
} from "antd";
const { Content } = Layout;
const { Option } = Select;
const { Text, Link } = Typography;
const { confirm } = Modal;

function NotificationList(props) {
  //form state :
  const [form] = Form.useForm();

  //table
  const [sortedInfo, setSortedInfo] = useState({});
  const [NotificationListState, setNotificationListState] = useState([]);
  const [isLoading, setLoading] = useState(false);

  //modal
  const [isModalSendVisible, setIsModalSendVisible] = useState(false);

  useEffect(() => {
    getListNotification();
  }, [isLoading]);

  const getListNotification = async () => {
    try {
      const res = await axios.get(API_URL + "/admin/notification", {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("accessToken")),
        },
      });
      if (res.status === 200) {
        setLoading(false);
        setNotificationListState(res.data);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  const showModalNotification = () => {
    setIsModalSendVisible(true);
  };

  const handleCancelNotification = () => {
    setIsModalSendVisible(false);
  };

  const handleOkMailNotification = async () => {
    sendNotification();
  };

  const sendNotification = async () => {
    try {
      const res = await axios.post(
        API_URL + "/admin/notification",
        {
          title: form.getFieldValue("title"),
          content: form.getFieldValue("content"),
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("accessToken")),
          },
        }
      );
      if (res.status === 201) {
        setIsModalSendVisible(false);
        setLoading(true);
        message.success("Your Notification has been send successfuly ", [3]);
      }
    } catch (err) {
      console.log("There was a problem with the server");
      message.success("Ops try again ", [3]);
    }
  };

  const columns = [
    {
      title: "Title Notification",
      key: "title",
      sorter: (a, b) => a.title.length - b.title.length,
      sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
      ellipsis: true,
      render: (text, record) => <a>{record.title}</a>,
    },
    {
      title: "Notification reference number",
      key: "Notification reference number",
      render: (text, record) => (
        <Text>{record._id.slice(record._id.length - 10).toUpperCase()}</Text>
      ),
    },
    {
      title: "Content Notification",
      dataIndex: "content",
      key: "content",
      sorter: (a, b) => a.content.length - b.content.length,
      sortOrder: sortedInfo.columnKey === "content" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Created At",
      key: "createdAt",
      render: (text, record) => (
        <Text>{record.createdAt.substring(0, 10)}</Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space direction="vertical">
          <Button
            onClick={() => showDeleteConfirm(record._id)}
            type="danger"
            icon={<DeleteOutlined />}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const deleteNotification = async (id) => {
    try {
      const res = await axios.delete(API_URL + "/admin/notification/" + id, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("accessToken")),
        },
      });
      if (res.status === 200) {
        setLoading(true);
        message.success("Your Notification has been delete successfuly ", [3]);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  const showDeleteConfirm = async (id) => {
    confirm({
      title: "Delete Alert !",
      icon: <ExclamationCircleOutlined />,
      content: "Do weent to delete this notification",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteNotification(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  if (!props.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Slider />
      <Layout className="site-layout">
        <Header headerTitle="Notification List" />
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}></Breadcrumb>
          <div
            className="site-layout-background"
            style={{ padding: 15 }}
          >
            <Form>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => showModalNotification()}
                  icon={<SendOutlined />}
                >
                  Send Notification
                </Button>
              </Form.Item>
              <Form.Item>
                <Table
                  pagination={{ pageSize: 4 }}
                  dataSource={NotificationListState}
                  columns={columns}
                  onChange={handleChangeTable}
                />
              </Form.Item>
              <Modal
                title="Send Notification To Users"
                width={500}
                icon={<InfoCircleOutlined />}
                visible={isModalSendVisible}
                onOk={handleOkMailNotification}
                onCancel={handleCancelNotification}
              >
                <Form
                  encType="multipart/form-data"
                  form={form}
                  style={{ paddingTop: "10px", textAlign: "center" }}
                  layout={"horizontal"}
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                >
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please entre subject !",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: 340,
                        margin: "0 10px 0 20px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Content"
                    name="content"
                    rules={[
                      {
                        required: true,
                        message: "Please entre content of the message  !",
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{
                        width: 350,
                        height: 150,
                        margin: "0 10px",
                      }}
                    />
                  </Form.Item>
                </Form>
              </Modal>
            </Form>
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  id_current_user: state.auth.id,
});

export default connect(mapStateToProps, { logout })(NotificationList);
