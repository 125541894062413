import React, { useEffect, useState } from "react";
import Footer from "../../layout/footers";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login_admin } from "../../redux/actions/auth";

import "antd/dist/antd.css";
import {
  FolderAddOutlined,
  EditOutlined,
  ArrowLeftOutlined,
  InboxOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./login.css";
import {
  Layout,
  Breadcrumb,
  Switch,
  notification,
  Select,
  Upload,
  Tag,
  Content,
  Input,
  Space,
  Card,
  Form,
  Button,
  Divider,
  InputNumber,
  Modal,
  Image,
  message,
  Typography,
  Row,
  Col,
} from "antd";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/login-animation";
import { LOGIN_FAIL } from "../../redux/CONSTANTS";

function Login(props) {
  const { Content } = Layout;
  const { Title } = Typography;
  //const [ToastMsg, setToastMsg] = useState();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [form] = Form.useForm();

  const showToastError = () => {
    if (props.error_login != null) {
      setTimeout(() => {
        notification["error"]({
          message: "",
          description: props.error_login,
          onClick: () => {
            console.log("Notification Clicked!");
            //props.dispatch({ type: LOGIN_FAIL });
          },
        });
      }, 0);
    }
  };

  const showToastSuccess = () => {
    if (props.error_login == null) {
      setTimeout(() => {
        notification["success"]({
          message: "",
          description: "Welcome " + props.username,
          onClick: () => {
            console.log("Notification Clicked!");
            //dipatch clear the error
            //props.dispatch({ type: AUTH_ERROR });
          },
        });
      }, 0);
    }
  };

  const onFinish = (values) => {
    showToastError();
    props.login_admin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  if (props.isAuthenticated) {
    //load user info if the the user Authenticated :
    //props.loadUser();
    showToastSuccess();
    return <Redirect to="/" />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        <Content
          style={{
            marginTop: "20px",
            height: "min-content",
          }}
        >
          <Card
            hoverable
            className="card"
            style={{ marginLeft: 20, marginRight: 20 }}
          >
            <Divider orientation="left" type="horizontal">
              <Title level={3}>Login Page</Title>
            </Divider>
            <Row
              style={{ alignItems: "center" }}>
              <Col flex={1}
                style={{ maxWidth: "50%" }}>
                <Lottie
                  //size={100}
                  style={{ padding: 20 }}
                  options={defaultOptions}
                  height={"auto"}
                  width={"auto"}
                />
              </Col>
              <Col flex={2}>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  encType="multipart/form-data"
                  form={form}
                  style={{

                  }}
                  align="right"
                  name="basic"
                  initialValues={{
                    remember: false,
                  }}
                >
                  <Space size="middle" direction="vertical">
                    <Form.Item
                      style={{ textAlign: "center" }}
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please entre email !",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: 450,
                          marginLeft: "20px",
                          textAlign: "left",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ textAlign: "center" }}
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please entre password !",
                        },
                      ]}
                    >
                      <Input.Password
                        style={{ width: 450, textAlign: "left" }}
                      />
                    </Form.Item>
                  </Space>
                  <Form.Item
                    style={{
                      paddingTop: "10px",
                      paddingRight: 180,
                      textAlign: "right",
                    }}
                  >
                    <Button
                      type="primary"
                      style={{ margin: "10px", width: "120px" }}
                      htmlType="submit"
                    >
                      Login
                    </Button>
                    <Button
                      style={{ margin: "10px", width: "120px" }}
                      htmlType="button"
                      onClick={onReset}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            {/* <Footer /> */}
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error_login: state.auth.error,
  username: state.auth.username,
  accessToken: state.auth.accessToken,
});

export default connect(mapStateToProps, { login_admin })(Login);

//export default Login;
