import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, CLEAR_USER } from "../CONSTANTS";
import axios from "axios";
import { API_URL } from "../../constants";

export const login_admin = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(API_URL + "/auth/admin-signin", formData);
    if (response.status != 200) {
      dispatch({
        type: LOGIN_FAIL,
        payload: response.data,
      });
    }
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    if (err.response) {
      dispatch({
        type: LOGIN_FAIL,
        payload: "Please Check Your Input",
      });
    }
  }
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: CLEAR_USER,
  });
};
