import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Slider from "../../layout/sliders";
import Footer from "../../layout/footers";
import Header from "../../layout/headers";
import axios from "axios";
import { API_URL } from "../../constants";
import delivery_box from "../../assets/images/delivery-box.png";
import infoModalTab from "../../components/infoModalTab";

//redux
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";

import "antd/dist/antd.css";
import {
  UserOutlined,
  FolderAddOutlined,
  InfoCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  RedoOutlined,
  ContactsOutlined,
  EditOutlined,
  SyncOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Breadcrumb,
  Table,
  Badge,
  Tooltip,
  AutoComplete,
  Avatar,
  Col,
  Row,
  Select,
  Tag,
  Spin,
  Input,
  Form,
  Space,
  Button,
  Switch,
  message,
  Modal,
  Popconfirm,
  Typography,
} from "antd";
const { Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;
const { Text, Link } = Typography;

function DeliveryList(props) {
  //form
  const [form] = Form.useForm();

  //table
  const [sortedInfo, setSortedInfo] = useState({});
  const [DeliveryListState, setDeliveryListState] = useState([]);
  const [StatusSelected, setStatusSelected] = useState("all");
  const [isLoading, setLoading] = useState(false);

  //fliter
  const [UsersListState, setUsersListState] = useState([]);
  const [EmailSelected, setEmailSelected] = useState("all");
  const [CountryFromListState, setCountryFromListState] = useState([]);
  const [CountryToListState, setCountryToListState] = useState([]);
  const [CountryToListSelect, setCountryToListSelect] = useState("all");
  const [CountryFromListSelect, setCountryFromListSelect] = useState("all");
  //const [defaultValueState, setdefaultValueState] = useState("all");

  //modal
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [ID, setID] = useState();

  useEffect(() => {
    getListDelivery();
  }, [
    isLoading,
    StatusSelected,
    EmailSelected,
    CountryToListSelect,
    CountryFromListSelect,
  ]);

  const getListDelivery = async () => {
    try {
      const res = await axios.get(API_URL + "/admin/delivery", {
        params: {
          status: StatusSelected,
          email: EmailSelected,
          countryto: CountryToListSelect,
          countryfrom: CountryFromListSelect,
        },
        headers: {
          Authorization: JSON.parse(localStorage.getItem("accessToken")),
        },
      });
      if (res.status === 200) {
        setLoading(false);
        setDeliveryListState(res.data);
        getCountryFrom(res.data);
        getCountryTo(res.data);
        getListUsers(res.data);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  const getListUsers = (data) => {
    var ListEmail = data.map((user) => user.user_id.email);
    var UniqueEmail = ListEmail.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setUsersListState(UniqueEmail);
  };

  const getCountryFrom = (data) => {
    var ListCountryFrom = data.map(
      (delivery) => delivery.deliverd_from.country
    );

    var UniqueCountryFrom = ListCountryFrom.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setCountryFromListState(UniqueCountryFrom);
  };

  const getCountryTo = (data) => {
    var ListCountryTo = data.map((delivery) => delivery.deliverd_to.country);
    var UniqueCountryTo = ListCountryTo.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setCountryToListState(UniqueCountryTo);
  };

  function handleChangeSelectStatus(value) {
    setStatusSelected(value);
  }

  function handleChangeSelectEmail(value) {
    setEmailSelected(value);
  }

  function handleChangeSelectAddressCountryFrom(value) {
    setCountryFromListSelect(value);
  }

  function handleChangeSelectCountryTo(value) {
    setCountryToListSelect(value);
  }

  const columns = [
    {
      title: "Title",
      key: "title",
      sorter: (a, b) => a.title.length - b.title.length,
      sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
      render: (text, record) => <Text>{record.title}</Text>,
    },
    {
      title: "Delivery reference number",
      key: "delivery reference number",
      render: (text, record) => <Text>{record.deliveryNumber}</Text>,
    },

    {
      title: "Origin country",
      key: "origin country",
      sorter: (a, b) =>
        a.deliverd_from.country.length - b.deliverd_from.country.length,
      sortOrder: sortedInfo.columnKey === "address from" && sortedInfo.order,
      render: (text, record) => <Text>{record.deliverd_from.country}</Text>,
    },
    {
      title: "Destination country",
      key: "destination country",
      sorter: (a, b) =>
        a.deliverd_to.country.length - b.deliverd_to.country.length,
      sortOrder: sortedInfo.columnKey === "address to" && sortedInfo.order,
      render: (text, record) => <Text>{record.deliverd_to.country}</Text>,
    },
    {
      title: "User Name",
      key: "name user",
      render: (text, record) => (
        <>
          <Avatar
            style={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              marginRight: "10px",
            }}
          >
            {record.user_id.name.substring(0, 1).toUpperCase()}
          </Avatar>
          <a>{record.user_id.name}</a>
        </>
      ),
    },

    {
      title: "Request Number",
      key: "number request",
      sorter: (a, b) => a.list_requests.length >= b.list_requests.length,
      sortOrder: sortedInfo.columnKey === "nbr request" && sortedInfo.order,
      render: (text, record) => (
        <span className="avatar-item">
          <Badge count={record.list_requests.length} showZero>
            <Avatar size={'auto'} shape="square" src={delivery_box} />
          </Badge>
        </span>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (text, record) =>
        record.status == "delivered" ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Delivered
          </Tag>
        ) : record.status == "charged" ? (
          <Tag icon={<ClockCircleOutlined />} color="warning">
            Charged
          </Tag>
        ) : record.status == "picked" ? (
          <Tag icon={<SyncOutlined spin />} color="geekblue">
            Picked
          </Tag>
        ) : record.status == "created" ? (
          <Tag icon={<ClockCircleOutlined />} color="processing">
            Created
          </Tag>
        ) : record.status == "canceled" ? (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Canceled
          </Tag>
        ) : null,
    },
    {
      title: "Created At",
      key: "createdAt",
      render: (text, record) => (
        <Text>{record.createdAt.substring(0, 10)}</Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space direction="vertical">
          <Button
            type="primary"
            style={{ width: "110px" }}
            icon={<InfoCircleOutlined />}
            onClick={() => infoModalTab(record)}
          >
            Details
          </Button>
          {(record.status == "created" && record.list_requests.length == 0) ||
            (record.status == "canceled" &&
              record.transporter_id == undefined) ? (
            <Button
              onClick={() => showDeleteConfirm(record._id)}
              type="danger"
              style={{ width: "110px" }}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          ) : (
            <Tooltip
              placement="top"
              title="You only can delete canceled or created delivery that only has 0 requests"
            >
              <Button
                disabled="true"
                type="danger"
                style={{ width: "110px", color: "#FF4D4F" }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Tooltip>
          )}
          <Button
            size="middle"
            color="warning"
            style={{
              width: "110px",
              backgroundColor: "#FCB362",
              color: "#fff",
            }}
            onClick={() => showModalUpdate(record)}
            icon={<EditOutlined />}
          >
            Edit Info
          </Button>
        </Space>
      ),
    },
  ];

  const showModalUpdate = async (record) => {
    setID(record._id);
    form.setFieldsValue({
      description_delivery_update: record.description,
      title_delivery_update: record.title,
    });
    setIsModalUpdateVisible(true);
  };

  const handleOkUpdate = async () => {
    console.log(form.getFieldValue("description_delivery_update"));
    if (
      form.getFieldValue("title_delivery_update").length > 0 &&
      form.getFieldValue("description_delivery_update").length > 0
    ) {
      try {
        const res = await axios.put(
          API_URL + "/admin/delivery/" + ID,
          {
            title: form.getFieldValue("title_delivery_update"),
            description: form.getFieldValue("description_delivery_update"),
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("accessToken")),
            },
          }
        );
        if (res.status === 201) {
          setLoading(true);
          setID("");
          setIsModalUpdateVisible(false);
          message.success("Delivery Info has been updated successfuly ", [3]);
        }
      } catch (err) {
        console.log("There was a problem with the server");
      }
    } else {
      message.error("Entre missing input", [2]);
    }
  };

  const handleCancelUpdate = () => {
    setIsModalUpdateVisible(false);
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  const resetFilter = () => {
    setStatusSelected("all");
    setEmailSelected("all");
    setCountryToListSelect("all");
    setCountryFromListSelect("all");
    form.setFieldsValue({
      countryfrom: "all",
      countryto: "all",
      email: "all",
      status: "all",
    });
  };

  const showDeleteConfirm = async (id) => {
    confirm({
      title: "Delete Alert !",
      icon: <ExclamationCircleOutlined />,
      content: "Do weent to delete this delivery",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteDelivery(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteDelivery = async (id) => {
    try {
      const res = await axios.delete(API_URL + "/admin/delivery/" + id, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("accessToken")),
        },
      });
      if (res.status === 200) {
        setLoading(true);
        message.success("Delivery has been delete successfuly ", [3]);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  if (!props.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Slider />
      <Layout className="site-layout">
        <Header headerTitle="Delivery List" />
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}></Breadcrumb>
          <div
            className="site-layout-background"
            style={{ padding: 15, }}
          >
            <Form form={form}>
              <Row justify="space-between">
                <Col>
                  <Form.Item name="status" label="Status">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                      defaultValue="all"
                      style={{ width: 200 }}
                      onChange={handleChangeSelectStatus}
                    >
                      <Option value="all">All</Option>
                      <Option value="created">Created</Option>
                      <Option value="canceled">Canceled</Option>
                      <Option value="charged">Charged</Option>
                      <Option value="delivered">Delivered</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item name="email" label="Email">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                      listHeight={100}
                      defaultValue="all"
                      style={{ width: 200 }}
                      onChange={handleChangeSelectEmail}
                    >
                      <Option value="all">All Email</Option>
                      {UsersListState.map((email) => (
                        <Option value={email}> {email}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="countryfrom" label="Origin Country">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                      defaultValue="all"
                      style={{ width: 200 }}
                      onChange={handleChangeSelectAddressCountryFrom}
                    >
                      <Option value="all">Countries</Option>
                      {CountryFromListState.map((country) => (
                        <Option value={country}> {country}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item name="countryto" label="Destination Country">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                      defaultValue="all"
                      style={{ width: 200 }}
                      onChange={handleChangeSelectCountryTo}
                    >
                      <Option value="all">Countries</Option>
                      {CountryToListState.map((country) => (
                        <Option value={country}> {country}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      danger
                      icon={<RedoOutlined />}
                      onClick={() => resetFilter()}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Table
                  pagination={{ pageSize: 4 }}
                  dataSource={DeliveryListState}
                  columns={columns}
                  onChange={handleChangeTable}
                />

                <Modal
                  title="Update Delivery"
                  width={500}
                  //icon={EditOutlined}
                  visible={isModalUpdateVisible}
                  onOk={handleOkUpdate}
                  onCancel={handleCancelUpdate}
                >
                  <Form
                    form={form}
                    style={{ paddingTop: "10px", textAlign: "center" }}
                    layout={"vertical"}
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                  >
                    <Form.Item
                      label="Title"
                      name="title_delivery_update"
                      rules={[
                        {
                          required: true,
                          message: "Please entre title delivery !",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: 350,
                          margin: "0 5px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="description_delivery_update"
                      rules={[
                        {
                          required: true,
                          message: "Please entre description delivery !",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{
                          width: 350,
                          height: 150,
                          margin: "0 10px",
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Modal>
              </Form.Item>
            </Form>
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  id_current_user: state.auth.id,
});

export default connect(mapStateToProps, { logout })(DeliveryList);
