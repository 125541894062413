import React, { Fragment } from "react";
import "antd/dist/antd.css";
import { Layout, Breadcrumb, Typography, Row, Col, Button } from "antd";
import { LogoutOutlined, HomeOutlined } from "@ant-design/icons";

//redux
import { connect } from "react-redux";
import { logout } from "../redux/actions/auth";
import { Redirect } from "react-router-dom";

const { Header } = Layout;
const { Title } = Typography;

function Headers(props) {
  if (!props.isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return (
    <Fragment>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <div>
          <Row>
            <Col flex={4}>
              <Breadcrumb style={{ margin: "16px" }}>
                <Breadcrumb.Item href="/">
                  <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span> {props.headerTitle}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col flex={0}>
              <Button
                onClick={props.logout}
                style={{
                  marginRight: "30px",
                  color: "#FF4D4F",
                }}
                icon={<LogoutOutlined />}
              >
                Logout
              </Button>
            </Col>
          </Row>
        </div>
      </Header>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Headers);
