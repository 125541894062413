import React, { useState, useEffect } from "react";
import Slider from "../../layout/sliders";
import Footer from "../../layout/footers";
import Header from "../../layout/headers";
import { API_URL } from "../../constants";
import infoModal from "../../components/infoModal";
import axios from "axios";
import { Redirect } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";

import "antd/dist/antd.css";
import {
  InfoCircleOutlined,
  UserOutlined,
  RedoOutlined,
  ContactsOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  FormOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Breadcrumb,
  Table,
  Col,
  Row,
  Select,
  Tag,
  Spin,
  Input,
  Form,
  Space,
  Button,
  Switch,
  message,
  Modal,
  Popconfirm,
  Typography,
} from "antd";
const { Content } = Layout;
const { Option } = Select;
const { Text, Link } = Typography;

function ClaimList(props) {
  //form
  const [form] = Form.useForm();

  //table & sort
  const [ClaimListState, setClaimListState] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});

  //modal
  const [isModalMailVisible, setIsModalMailVisible] = useState(false);
  const [ID, setID] = useState("");

  //filter
  const [ListEmailSelected, setListEmailSelected] = useState([]);
  const [EmailSelected, setEmailSelected] = useState("");
  const [StatusSelected, setStatusSelected] = useState("");

  useEffect(() => {
    getListClaim();
  }, [isLoading, StatusSelected, EmailSelected]);

  const getListClaim = async () => {
    //JSON.parse(localStorage.getItem("accessToken"))
    try {
      const res = await axios.get(API_URL + "/admin/claim", {
        params: { status: StatusSelected, email: EmailSelected },
        headers: {
          Authorization: JSON.parse(localStorage.getItem("accessToken")),
        },
      });
      if (res.status === 200) {
        console.log(res.data);
        setLoading(false);
        setClaimListState(res.data);
        getEmailUserClaim(res.data);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  const getEmailUserClaim = (data) => {
    console.log(data);

    var ListEmailUserClaim = data.map((user) => user.email);

    var UniqueEmail = ListEmailUserClaim.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setListEmailSelected(UniqueEmail);
  };

  function handleChangeSelect(value) {
    setStatusSelected(value);
  }

  function handleChangeSelectEmail(value) {
    setEmailSelected(value);
  }

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  const resetFilter = () => {
    setStatusSelected("");
    setEmailSelected("");
    form.setFieldsValue({
      email: "all",
      status: "",
    });
  };

  const columns = [
    {
      title: "subject",
      dataIndex: "subject",
      key: "subject",
      // sorter: (a, b) => a.subject.length - b.subject.length,
      // sortOrder: sortedInfo.columnKey === "subject" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      // sorter: (a, b) => a.content.length - b.content.length,
      // sortOrder: sortedInfo.columnKey === "content" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          <Space size="middle">
            {record.status ? (
              <Tag icon={<CheckCircleOutlined />} color="success">
                Reviewed
              </Tag>
            ) : (
              <Tag icon={<SyncOutlined spin />} color="warning">
                Onhold
              </Tag>
            )}
          </Space>
        </>
      ),
    },
    {
      title: "created At",
      key: "createdAt",
      render: (text, record) => (
        <Text>{record.createdAt.substring(0, 10)}</Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space direction="vertical">
          <Button
            type="primary"
            icon={<InfoCircleOutlined />}
            onClick={() => infoModal(record, "claim")}
          >
            Details
          </Button>
          {!record.status ? (
            <Button
              icon={<FormOutlined />}
              onClick={() => showModalMail(record)}
            >
              Review
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  const showModalMail = async (record) => {
    setID(record._id);
    form.setFieldsValue({
      email: record.email,
    });
    setIsModalMailVisible(true);
  };

  const handleCancelMail = () => {
    setIsModalMailVisible(false);
  };

  const handleOkMail = async () => {
    reviewClaim();
  };

  const reviewClaim = async () => {
    try {
      const res = await axios.put(
        API_URL + "/admin/reviewClaim/" + ID,
        {
          email: form.getFieldValue("email"),
          subject: form.getFieldValue("subject"),
          content: form.getFieldValue("content"),
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("accessToken")),
          },
        }
      );
      if (res.status === 201) {
        setIsModalMailVisible(false);
        setLoading(true);
        message.success("Claim has been reviewed successfuly ", [3]);
      }
    } catch (err) {
      console.log("There was a problem with the server");
    }
  };

  if (!props.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Slider />
      <Layout className="site-layout">
        <Header headerTitle="Claim List" />
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}></Breadcrumb>
          <div
            className="site-layout-background"
            style={{ padding: 15 }}
          >
            <div>
              <Form form={form}>
                <Row justify="space-between">
                  <Col span={5}>
                    <Form.Item name="status" label="Status">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                        }
                        defaultValue=""
                        style={{ width: 200 }}
                        onChange={handleChangeSelect}
                      >
                        <Option value="">All</Option>
                        <Option value="true">Reviewed</Option>
                        <Option value="false">Onhold</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="email" label="Email">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                        }
                        listHeight={100}
                        defaultValue="all"
                        style={{ width: 200 }}
                        onChange={handleChangeSelectEmail}
                      >
                        <Option value="all">All Email</Option>
                        {ListEmailSelected.map((email) => (
                          <Option value={email}> {email}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item>
                      <Button
                        type="primary"
                        danger
                        icon={<RedoOutlined />}
                        onClick={() => resetFilter()}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Table
                    pagination={{ pageSize: 4 }}
                    dataSource={ClaimListState}
                    columns={columns}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Modal
                  title="Send Email To User"
                  width={500}
                  visible={isModalMailVisible}
                  onOk={handleOkMail}
                  onCancel={handleCancelMail}
                >
                  <Form
                    encType="multipart/form-data"
                    form={form}
                    //style={{ paddingLeft: "100px", paddingTop: "10px" }}
                    style={{ paddingTop: "10px" }}
                    layout={"vertical"}
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please entre email of the user !",
                        },
                      ]}
                    >
                      <Input
                        disabled="false"
                        style={{ width: 350, margin: "0 10px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        {
                          required: true,
                          message: "Please entre subject !",
                        },
                      ]}
                    >
                      <Input style={{ width: 350, margin: "0 10px" }} />
                    </Form.Item>
                    <Form.Item
                      label="Content"
                      name="content"
                      rules={[
                        {
                          required: true,
                          message: "Please entre content of the message  !",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{
                          width: 350,
                          height: 150,
                          margin: "0 10px",
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Modal>
              </Form>
            </div>
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  id_current_user: state.auth.id,
});

export default connect(mapStateToProps, { logout })(ClaimList);
